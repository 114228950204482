import React, {useState} from 'react';
import axios from 'axios';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import {makeStyles, ThemeProvider } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    showsContainer:{
        margin: '0px 10vh'
    },
    showsContainerMobile:{
        margin: '0px 0vh'
    },
    image:{
        //maxHeight: '30vh'
        width: '100%',
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
      
    },
    imageMobile:{
        //maxHeight: '30vh'
        width: '100%',
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    showContainer:{
        boxShadow: '4px 4px 6px -1px grey',
        border: '2px solid black',
        borderRadius: '5px',
        padding: '1vh',
        margin: "1rem",
        textAlign: 'left'
    },
    imgContainer:{
        display: 'block',
        overflowY: 'hidden',
        height: '300px',
        width: '300px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    imgContainerContainer:{
        height:'100%',
        width: '100%',
        margin: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    showTitle:{
        fontWeight: 'bold',
        padding: '10px 0px',
        textTransform:'uppercase'
        
    },
    showDescription:{
        fontSize:'1.5vh',
        overflow:'hidden'
    }
}));

export default function Shows(props){
    const classes = useStyles();
    const [shows, setShows] = useState([]);
    const sortAndSetShows = (showz) => {
        //sorting shows alphabetically by name
        showz.sort(function(a,b) {
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0
        });
        if(!_.isEqual(shows, showz)) setShows(showz);
    }
    axios.get( process.env.REACT_APP_API_URL + '/api/shows').then( response => {
        //if(!_.isEqual(shows, response.data)) setShows(response.data);
        sortAndSetShows(response.data);
    })

    if(shows.length == 0){
        return (
            <div className={classes.showsContainer}>
                Loading...
            </div>
        )

    }else{
        return(
            <div className={props.mediaSize == "phone" ? classes.showsContainerMobile : classes.showsContainer}>
                Shows
                <Grid container>
                    {shows.map((show, kee) => {
                        return(
                            <Grid key={kee} item xs={12} sm={12} md={6} lg={3}>
                                <ShowInfo mediaSize={props.mediaSize} name={show.name} image={show.image} description={show.description}>
                                </ShowInfo>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        )
    }
}

function ShowInfo(props){
    const classes = useStyles();
    return(
        <div className={classes.showContainer}>
            <div className={classes.imgContainerContainer}>
                <div className={classes.imgContainer}>
                    {props.image &&
                        <img className={props.mediaSize == "phone" ? classes.imageMobile : classes.image} src={ 'data:image/jpeg;base64, ' + props.image}/>
                    }
                </div>
            </div>
            <div className={classes.showTitle}>
                {props.name}
            </div>
            <div className={classes.showDescription}>
                {props.description}
            </div>
        </div>
    )
}