import React, { useState, useEffect, useRef } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
        minHeight: '15vh',
        textAlign: 'center',
        padding: '20px',
        //backgroundColor: '#BBDEF0',
        boxShadow: '4px 4px 6px -1px',
        fontWeight: 'bold',
  
    },
    control: {
      padding: theme.spacing(2),
    },
    header:{
        fontSize: '3rem',
        textAlign: 'center',
        //color: '#BBDEF0',
        paddingBottom: '60px'
    },
    selectorContainer:{
        padding: '5%'
    },
    error:{

    }
  }));

  export default function Login(props){
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState(null);
    const classes = useStyles();

    const Login = () =>{
        axios.post('https://localhost:44305/api/authorization/login', {Uname: username, Pass: password})
            .then(response => { 
                
                if(response.data == "" || response.data == null){
                    setError('Incorrect login info. Please try again...');
                }else{
                    props.setAuthToken(response.data);
                }
            })
    }


    return(
        <div className={classes.selectorContainer}>
            <div className={classes.header}>Admin Section Radio</div>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        Login
                        <div>
                            <TextField 
                                label="Username"
                                value={username}
                                onChange={(e) => {if(username != e.target.value) {setError(null); setUsername(e.target.value)}} }
                                />
                        </div>
                        <div>
                            <TextField
                                id="standard-password-input"
                                label="Password"
                                type="password" 
                                value={password}
                                onChange={(e) => {if(password != e.target.value) {setError(null); setPassword(e.target.value)}} }
                            />
                        </div>
                        <div>
                            <Button variant="contained" onClick={() => Login()}>Login</Button>
                        </div>
                        {error && 
                            <div className={classes.error}>
                                {error}
                            </div>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
  }