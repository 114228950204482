import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Message from './Message';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    chatWindow:{
        flex: '1 1 auto'
    },
    overflowHider:{
    },
    worksHeight: {
        height: '80vh',
        display: 'flex',
        flexFlow: 'column',
        overflowY: 'auto',
        wordWrap: 'break-word'
    },
    worksHeightMobile: {
        height: '77.6vh',
        display: 'flex',
        flexFlow: 'column',
        overflowY: 'auto',
        wordWrap: 'break-word'
    },
    desktopHeight: {
        height: '88vh',
        display: 'flex',
        flexFlow: 'column',
        overflowY: 'auto',
        wordWrap: 'break-word'
    }
}));

const ChatWindow = (props) => {
    const theme = useTheme();
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
  
    const classes = useStyles();
    const chat = props.chat
        .map(m => <Message 
            key={Date.now() * Math.random()}
            user={m.user}
            message={m.message}
            color={m.color}/>);

    //its hacky as fuck im so sorry
    var heightClass= lg ? classes.desktopHeight : classes.worksHeight;
    if(props.mobile){
        heightClass = classes.worksHeightMobile;
    }
    return(
        <div className={classes.chatWindow }>
            <div className={heightClass}>   
                {chat}
            </div>
        </div>
    )
};

export default ChatWindow;