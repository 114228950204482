import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
//import App from './components/UpToDate/FrozenSection';
import FSR from "./components/FrozenSectionRadio";
import Shows from "./components/Shows";
import ShowPage from "./components/ShowPage";
import GenrePage from "./components/GenrePage";

import Genres from "./components/Genres";
import About from "./components/About";

import AS from "./components/Admin/AdminSection";
//import App from './components/RoutingWrapper';
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <FSR />
        </Route>
        <Route path="/admin">
          <AS />
        </Route>
        <Route exact path="/shows">
          <Shows />
        </Route>
        <Route exact path="/shows/:id" component={ShowPage} />
        {/* <ShowPage />
        </Route> */}
        <Route exact path="/genres/:id" component={GenrePage} />
        {/* <GenrePage />
        </Route> */}
        <Route exact path="/genres">
          <Genres />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/*">
          <FSR />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
