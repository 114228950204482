import React from 'react';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SendIcon from '@material-ui/icons/Send';
import Grid from '@material-ui/core/Grid'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
    optionsPanel:{
        fontFamily: 'Times New Roman',
        borderStyle: "inset",
        border: "5px solid #981266",
        background: '#060006',
        color: '#fff',
        padding: '0.5vh'
    },
    optionsPanelMobile:{
        fontFamily: 'Times New Roman',
        borderStyle: "inset",
        border: "5px solid #981266",
        background: '#060006',
        color: '#fff',
        padding: '0.5vh'
    },
    clickable:{
    },
    exitIcon:{
        height: '100%'
    },
    lougoutWrapper:{
    },
    centered:{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column'
    },
    logoutButton:{
        width: '50px'
    }
}));
export default function OptionsPanel(props){

    const classes = useStyles();
    const logout = (e) => {
        e.preventDefault();
        const cookies = new Cookies();
        cookies.remove('fsr-chat-color', {path: '/'});
        cookies.remove('fsr-chat-token', {path: '/'});
        props.setUname(null);
        props.setColor('#fff');
    }
    const exit = (e) => {
        e.preventDefault();
        props.setAltPage(null);
    }
    return(
        <div className={props.mobile ? classes.optionsPanelMobile: classes.optionsPanel}>
            <Grid container>
                <Grid xs={11} >
                    <div className={classes.centered}>
                        {props.uname && <a className={classes.clickable + ' ' + classes.logoutButton} onClick={logout}>Logout</a> }
                    </div>
                </Grid>
                <Grid xs={1}>
                    <div className={classes.clickable + ' ' + classes.centered} onClick={exit}><ExitToAppIcon className={classes.exitIcon}/></div>
                </Grid>
            </Grid>
        </div>
    )
}
