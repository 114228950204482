import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    /*
    showCard:{
        backgroundColor: '#BBDEF0',
        borderRadius: '5px',
        padding: '0.5rem',
        color: '#010001',
        textAlign: 'left',
        boxShadow: '1px 1px 1px 1px #e419a1'
    },
    name:{
        fontWeight: 'bold',
    },
    description:{
        fontSize: '1.5vh'
    },
    showImg:{
        maxWidth: '100%'
    },
    topRight:{
        float: 'right'
    }
    */
    topRight:{
        textAlign:'right'
    },
    imageContainer:{
        display: 'block',
        overflowY: 'hidden',
        height: '300px',
        width: '300px',
        margin: 'auto'
    },
    imgContainerContainer:{
        height:'100%',
        width: '100%',
        margin: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        pading: '10px 0px'
    },
    image:{
        //maxHeight: '30vh'
        width: '100%',
        borderRadius: '5px',
        objectFit: 'contain'
    },
    showContainer:{
        boxShadow: '4px 4px 6px -1px grey',
        border: '2px solid black',
        borderRadius: '5px',
        padding: '1vh',
        margin: '20px',
        textAlign: 'left',
        margin: "1rem"
    },
    showTitle:{
        padding: '10px 0px'
    },
    name:{
        width: '100%'
    },
    showDescription:{
        overflow:'hidden',
        width: '100%'
    },
    description:{
        
        width: '100%'
    }
}));

export default function ShowCard(props){

    const [show, setShow] = useState(null);
    const [altered, setAltered] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if(deleted) setDeleted(false);
        if(altered) setAltered(false);
        if(!_.isEqual(show, props.show)) setShow(_.cloneDeep(props.show));
    }, [props.show])

    const alterShow = (val, field) => {
        if(!altered) setAltered(true);
        var showCopy = _.cloneDeep(show);
        showCopy[field] = val;
        if(!_.isEqual(show, showCopy)) setShow(showCopy);
    }

    const submitChanges = () =>{
        setLoading(true);
        if(deleted){
            axios.post(process.env.REACT_APP_API_URL + '/api/shows/delete?token=' + props.authToken, show)
            .then(response => {props.updateShows(response.data); setLoading(false);})
        }
        else{
            axios.post(process.env.REACT_APP_API_URL + '/api/shows/update?token=' + props.authToken, show)
            .then(response => {props.updateShows(response.data); setLoading(false);});
        }
    }
    const revertChanges = () => {
        if(deleted) setDeleted(false)
        if(altered) setAltered(false);
        if(!_.isEqual(show, props.show)) setShow(props.show);
    }
    const imageUpload = (e) => {
        if(e.target.files && e.target.files[0]){
            var file = e.target.files[0];
            
            var reader = new FileReader();
            reader.readAsBinaryString(file);

            reader.onload = function() {
                var tempShow = _.cloneDeep(show);
                tempShow.image = btoa(reader.result);
                if(!altered) setAltered(true);
                if(!_.isEqual(tempShow, show)) setShow(tempShow)
            };
            reader.onerror = function() {
                console.log('there are some problems');
            };
        }
    }

    const deleteShow = () =>{
        if(!altered) setAltered(true);
        if(!deleted) setDeleted(true);
    }
    

    if(show){
        if(loading){
            return(
                <div className={classes.showContainer}>
                    <CircularProgress/>
                </div>
            )
        }else{
            return(
                <div className={classes.showContainer}>
                    <div>
                        <div className={classes.topRight}>
                            <Button variant="contained" onClick={() => deleteShow()}>Delete Show</Button>
                        </div>
                    </div>
                    {deleted ? <div>{show.name} is slated for deletion, submit to finalize</div> :
                        <>
                            <div className={classes.imgContainerContainer}>
                                <div className={classes.imageContainer}>
                                    {show.image &&
                                        <img className={classes.image} src={'data:image/jpeg;base64, ' + show.image}/>
                                    }
                                </div>
                            </div>
                            <Input type="file" onChange={imageUpload}></Input>
                            <div className={classes.showTitle}>
                                Name: <TextField className={classes.name}
                                    value={show.name}
                                    onChange={(e) => alterShow(e.target.value, 'name')}
                                />
                            </div>
                            <div  className={classes.showDescription}>
                                Description: 
                                <div>
                                    <TextField className={classes.description}
                                        value={show.description}
                                        multiline
                                        onChange={(e) => alterShow(e.target.value, 'description')}
                                    />
                                </div>
                            </div>
                        </>
                    
                    }
                    
                    {altered && 
                        <>
                            <Button variant="contained" onClick={() => submitChanges()}>Submit Changes</Button>
                            <Button variant="contained" onClick={() => revertChanges()}>Revert Changes</Button>
                        </>
                    }
                    

                </div>
            )
        }
    }else{
        return(
            <></>
        )
    }
}