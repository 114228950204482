import React from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { PinDropSharp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  bottomBarContainer: {
    color: "#fff",
    position: "fixed",
    bottom: "5vh",
    width: "100%",
  },
  flex: {
    justifyContent: "space-around",
  },
  linkItem: {
    color: "#fff",
    zIndex: 1000,
    alignItems: "center",
    paddingTop: "30px",
    cursor: "inherit",
    textAlign: "center",
    "& a": {
      color: "#fff",
      padding: "30px",
      textDecoration: "none",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4vh",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "inherit",
    },
  },
}));
export default function BottomBar(props) {
  const classes = useStyles();
  /*
    var links = [
        {
            link:'#',
            text:'Archive'
        },
        {
            link: '#',
            text: 'Schedule'
        },
        {
            link: '#',
            text: 'DJs'
        },
        {
            link: '#',
            text: 'Events'
        },
        {
            link:'#',
            text:'Projects'
        },
        {
            link: '#',
            text: 'Support'
        },
        {
            link: '#',
            text:'About'
        }
    ];
    */

  var links = [
    {
      link: "https://www.mixcloud.com/fsrlive/",
      text: "Archive",
    },
    {
      link: () => {
        props.setAltPage("sched");
      },
      text: "Schedule",
    },
    // {
    //     link: () => {props.setAltPage("shows");},
    //     text:'Shows'
    // },
    // {
    //     link: () => {props.setAltPage("chat");},
    //     text:'Chat'
    // },
    {
      link: "/shows",
      text: "Shows",
    },
    {
      link: "https://shop.fsr.live/",
      text: "Shop",
    },
    {
      link: "https://linktr.ee/fsrlive",
      text: "Links",
    },
  ];

  var el = links[0];
  if (props.mediaSize !== "phone") {
    return (
      <div className={classes.bottomBarContainer}>
        <Grid container className={classes.flex}>
          {links.map((el, i) => {
            return (
              <Grid className={classes.linkItem} item xs={2}>
                {typeof el.link == "function" ? (
                  <a onClick={el.link}>{el.text}</a>
                ) : (
                  <a target={el.text !== "Shows" && "_blank"} href={el.link}>
                    {el.text}
                  </a>
                )}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  } else {
    return (
      <div className={classes.bottomBarContainer}>
        <Grid container className={classes.flex}>
          {links.map((el, i) => {
            return (
              <Grid className={classes.linkItem} item xs={4}>
                {typeof el.link == "function" ? (
                  <a onClick={el.link}>{el.text}</a>
                ) : (
                  <a target={el.text !== "Shows" && "_blank"} href={el.link}>
                    {el.text}
                  </a>
                )}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}
