import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import axios from 'axios';
import ShowCard from './ShowCard';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CreateShowDialog from './CreateShowDialog';

const useStyles = makeStyles((theme) => ({
    backArrow:{

    },
    showGrid:{
    },
    showDetailsContainer:{
        textAlign: 'center'
    },
    showDeetHeader: {
        fontSize: '3rem'
    },
    newShow:{
        borderRadius: '5px',
        //backgroundColor: '#BBDEF0',
        padding: '0.5rem',
        color: '#010001',
        textAlign: 'left',
        boxShadow: '1px 1px 1px 1px #e419a1'
    },
    container:{
        //backgroundColor: '#010001'
    }
    
}));


export default function ShowDetails(props){
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newShow, setNewShow] = useState(false);

    console.log('cur shows');
    console.log(data);
    const updateShows = (neu) =>{
        //sorting shows alphabetically by name
        neu.sort(function(a,b) {
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0
        });

        if(!_.isEqual(data, neu)) setData(neu);
        if(loading) setLoading(false);
        if(newShow) setNewShow(false);
    }

    useEffect(async () => {
        if(!loading) setLoading(true);
        const result = await axios(
            process.env.REACT_APP_API_URL + '/api/shows',
        ).then(response => {
            updateShows(response.data);
        })
    }, []);

    const createShow = () =>{
        /*  
        axios.post(process.env.REACT_APP_API_URL + '/api/shows/create?token=' + props.authToken,
        {
            Name:"",
            Description:null,
            Image:null,
            Host:null,
            Genre:null,
            ShowTime:null
        })
            .then(response => updateShows(response.data));
        */
        setNewShow(true);
    }
    if(loading){
        return(
            <div>
                <div className={classes.backArrow} onClick={() => {props.setCurrView("root")}}>back</div>
                <div className={classes.showDetailsContainer}>
                    <div className={classes.showDeetHeader}>
                        Show Details Manipulation
                    </div>
                    <div className={classes.warning}>
                        Loading...
                    </div>
                </div>
            </div>
        )
    }
    else{
        return(
            <div>
                <div className={classes.backArrow} onClick={() => {props.setCurrView("root")}}>back</div>
                <div className={classes.showDetailsContainer}>
                    <div className={classes.showDeetHeader}>
                        Show Details Manipulation
                    </div>
                    <div className={classes.warning}>
                        <div>
                            (warning, this is live data!)
                        </div>
                        <div>
                            <Button variant="contained" onClick={() => createShow()}>Create Show</Button>
                        </div>
                    </div>
                    {data && 
                    <Grid container className={classes.container}>
                        {data.map((show, kee) => {
                            return(
                                <Grid key={kee} xs={12} sm={12} md={6} lg={3} className={classes.showGrid}>
                                    <ShowCard key={kee} show={show} updateShows={updateShows} authToken={props.authToken}/>
                                </Grid>
                            )
                        })}
                        
                        
                    </Grid>}
                    {newShow && <CreateShowDialog updateShows={updateShows} authToken={props.authToken}></CreateShowDialog>}
                </div>
            </div>
        )
    }
}