import React, { useState, useEffect, useRef } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ChatInput from './ChatInput';
import ChatWindow from './ChatWindow';
import OptionsPanel  from './OptionsPanel';
import Cookies from 'universal-cookie';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    chatContainer:{
        width: '25vw',
        backgroundColor: '#060006',
        color: '#fff',
        borderStyle: "inset",
        border: "5px solid #981266",
        flex: '1 1 auto'
    },
    chatContainerMobile:{
        backgroundColor: '#060006',
        color: '#fff',
        borderStyle: "inset",
        border: "5px solid #981266",
        flex: '1 1 auto',
        
    },
    chatContainerFlexr:{
        
        display: 'flex',
        flexFlow: 'column',
        height: '100%'
    },
    wholeAssThing:{
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
    },
    wholeAssThingMobile:{
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        width: '100%'
    },
    chatDrawerMobile:{
        width:'100%'
    }
});


function ChatContainer(props){
    const classes = useStyles();
    const [ connection, setConnection ] = useState(null);
    const [ chat, setChat ] = useState([]);
    const latestChat = useRef(null);

     
    latestChat.current = chat;

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_URL + '/hubs/chat')
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);
    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
    
                    connection.on('ReceiveMessage', message => {
                        const updatedChat = [...latestChat.current];
                        
                        updatedChat.push(message);
                    
                        setChat(updatedChat);
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    const sendMessage = async (user, message, color) => {
        const chatMessage = {
            user: user,
            message: message,
            color: color
        };

        if (connection.connectionStarted) {
            try {
                await connection.send('SendMessage', chatMessage);
            }
            catch(e) {
                console.log(e);
            }
        }
        else {
            alert('No connection to server yet.');
        }
    }
    return(
        <div className={props.mobile ? classes.wholeAssThingMobile : classes.wholeAssThing}>
            <OptionsPanel  mobile={props.mobile} uname={props.uname} setUname={props.setUname} color={props.color} setColor={props.setColor} setAltPage={props.setAltPage}/>
            <div className={props.mobile ? classes.chatContainerMobile : classes.chatContainer} >
                <div className={classes.chatContainerFlexr}>
                    <ChatWindow mobile={props.mobile} chat={chat}/>
                    <ChatInput uname={props.uname} setUname={props.setUname} color={props.color} setColor={props.setColor} sendchatMessage={sendMessage} />
                </div>
            </div>
        </div>
    )
}

export function ChatInPopUp(props){
    const [uname, setUname] = useState(null);
    const [color, setColor] = useState('#fff');

    //storing username in chatToken if we havent already got one
    const cookies = new Cookies()
    var chatToken = cookies.get('fsr-chat-token');
    
    var colorToken = cookies.get('fsr-chat-color');
    if(typeof chatToken !== undefined){
        if(uname != chatToken) setUname(chatToken);
    }
    if(typeof colorToken !== undefined){
        if(color != colorToken) setColor(colorToken);
    }
    

    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }

        setState({ ...state, [anchor]: open });
    };
    return (
        <ChatContainer uname={uname} setUname={setUname} setAltPage={props.setAltPage} setColor={setColor} color={color}/>
);
}

export function ChatDrawer(props) {
    const [uname, setUname] = useState(null);
    const [color, setColor] = useState('#fff');

    //storing username in chatToken if we havent already got one
    const cookies = new Cookies()
    var chatToken = cookies.get('fsr-chat-token');
    
    var colorToken = cookies.get('fsr-chat-color');
    if(typeof chatToken !== undefined){
        if(uname != chatToken) setUname(chatToken);
    }
    if(typeof colorToken !== undefined){
        if(color != colorToken) setColor(colorToken);
    }
    

    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }

        setState({ ...state, [anchor]: open });
    };

    var isMobile = props.MediaSize === "phone";
    return (
            <Drawer borderLeft={1} borderColor={"#EE1DA0"} className={isMobile ? classes.chatDrawerMobile  + ' mobile-chat-drawer': classes.chatDrawer} variant="persistent" anchor={'right'} open={props.isOpen} onClose={toggleDrawer('right', false)}>
                <ChatContainer mobile={isMobile} uname={uname} setUname={setUname} setAltPage={props.setAltPage} setColor={setColor} color={color}/>
            </Drawer>
    );
}