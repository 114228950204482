import React, { useEffect, useRef, useState } from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import StarBG from "../Assets/JustStars_v01.mp4";
import StarBGPng from "../Assets/JustStars_v01.png";
import StarBGgif from "../Assets/JustStars_v01.gif";
import SolarSys from "../Assets/SolarSystem_ZOOM_v02.mp4";
import SolarSysPng from "../Assets/FSR_SolarSystemPlayer_v01.png";
import SolarSysGif from "../Assets/SolarSystem_ZOOM_v02.gif";
import SolarSysGifFrameZero from "../Assets/SolarSystem_ZOOM_v02_Frame_0.gif";
import IconBar from "./IconBar";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import fsrLogo from "../Assets/FSRLogo.png";
import { client } from "../client";

import FsrAppbar from "./Header.js";
import PopUpPanel from "./PopUpPanel.js";
import FsrTheme from "../styles/FsrTheme.js";
import BottomBar from "./BottomBar";
import _ from "lodash";
import GifPlayer from "react-gif-player";
import enableInlineVideo from "iphone-inline-video";
import { ContactSupportOutlined, TrendingUpOutlined } from "@material-ui/icons";
import { ChatDrawer, ChatInPopUp } from "./Chat/ChatDrawer";

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

//obviously change this.... please?
//var audioSrc = "https://n10as.out.airtime.pro/n10as_a"
var audioSrc = "https://fsrlive.out.airtime.pro/fsrlive_a";
const useStyles = makeStyles((theme) => ({
  bgVideo: {
    objectFit: "cover",
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
  },
  viewportHeader: {
    position: "relative",
    height: "100vh",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  solarSys: {
    height: "50vh",
    top: "15vh",
    objectFit: "cover",
    position: "fixed",
  },
  solarSysTablet: {
    height: "35vh",
    top: "26vh",
    objectFit: "cover",
    position: "fixed",
  },
  /*
      sys2560: {
          height: "51%",
          width: "45%",    
      },
      sys1920: {
          height: "51%",
          width: "50%",    
      },
      sys1024:{
          height: "51%",
          width: "50%"
      },
      */
  playPause: {
    //zIndex: 1000,
    color: "#fff",
    height: "10vh",
    width: "10vw",
    position: "fixed",
    top: "35.5vh",
    left: "44.9vw",
  },
  playPauseTablet: {
    //zIndex: 1000,
    color: "#fff",
    height: "7vh",
    width: "15vw",
    position: "fixed",
    top: "40.5vh",
    left: "42.1vw",
  },
  iconArea: {
    width: "100%",
    position: "fixed",
    bottom: "0vh",
  },
  fsrBG: {
    backgroundColor: "#010001",
  },
  fsrLogo: {
    maxWidth: "112px",
    position: "relative",
    zIndex: "100",
    ["@media (max-width:940px)"]: {
      maxWidth: "182px",
      marginBottom: "20px",
    },
  },
  fsrLogoMain: {
    maxWidth: "400px",
    margin: "0 auto",
    marginBottom: "-50px",
    ["@media (max-width:940px)"]: {
      display: "none",
    },
    // ["@media (max-width:940px)"]: {
    //   maxWidth: "182px",
    //   marginBottom: "20px",
    // },
  },
  nav: {
    padding: "0 24px",
    position: "relative",
    zIndex: 100,
    display: "flex",
    alignItems: "center",
    zIndex: 100,
    ["@media (max-width:940px)"]: {
      flexDirection: "column",
    },
  },
  about: {
    position: "relative",
    maxWidth: "900px",
    zIndex: 100,
    display: "flex",
    alignItems: "center",
    // height: "40vw",
    marginTop: "0",
    width: "50vw",
    margin: "0 auto",
    zIndex: 100,
    justifyContent: "center",
    color: "white",
    flexDirection: "column",
    alignItems: "baseline",
    gap: "30px",
    paddingBottom: "70px",
    ["@media (max-width:940px)"]: {
      width: "80%",
      fontSize: "20px",
    },
  },
}));

const TEST_QUERY = gql`
  {
    books {
      title
      author
    }
  }
`;

export default function About() {
  const [play, setPlay] = useState(false);
  const [muted, setMuted] = useState(false);
  const [altPage, setAltPageState] = useState(null);

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [showEnds, setShowEnds] = useState(null);

  const classes = useStyles();

  const bgStarRef = useRef(null);
  const solarSysRef = useRef(null);
  const audioRef = useRef(null);

  const [about, setAbout] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await client.getEntries();
      const aboutCopy = data.items.filter((item) => {
        return item.sys.contentType.sys.id === "about";
      });
      setAbout(aboutCopy);
    };
    fetchData();
  }, []);

  var SolarSysClass;
  var PlayPause;
  var MediaSize = "monitor";
  const monitor1025 = useMediaQuery("(min-width:1025px)");
  const tablet500 = useMediaQuery("(min-width:500px)");
  var bgJSX = (
    <video
      playsinline
      ref={bgStarRef}
      muted
      loop
      className={classes.bgVideo}
      id="bgVid"
      src={StarBGgif}
    ></video>
  );
  //larger than tablet
  if (monitor1025) {
    SolarSysClass = classes.solarSys;
    PlayPause = classes.playPause;
    MediaSize = "monitor";
  } else if (tablet500) {
    SolarSysClass = classes.solarSysTablet;
    PlayPause = classes.playPauseTablet;
    MediaSize = "tablet";
    bgJSX = (
      <img
        ref={bgStarRef}
        className={classes.bgVideo}
        id="bgVid"
        src={StarBGgif}
      />
    );
  } else {
    SolarSysClass = classes.solarSysTablet;
    PlayPause = classes.playPauseTablet;
    MediaSize = "phone";
    bgJSX = (
      <img
        ref={bgStarRef}
        className={classes.bgVideo}
        id="bgVid"
        src={StarBGgif}
      />
    );
  }

  const getShowEnds = (bannerData) => {
    if (bannerData && bannerData.current) {
      var curShow = bannerData.current;
      if (curShow.ends) {
        setShowEnds(curShow.ends);
      }
    }
  };

  const updateBannerData = () => {
    var myHeaders = new Headers();
    myHeaders.append("pragma", "no-cache");
    myHeaders.append("cache-control", "no-store");

    //still needs testing
    fetch("https://fsrlive.airtime.pro/api/live-info", {
      method: "GET",
      cache: "no-cache",
      pragma: "no-cache",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (!isLoaded) setIsLoaded(true);
          if (!_.isEqual(data, result)) setData(result);
          getShowEnds(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (reserror) => {
          if (!isLoaded) setIsLoaded(true);
          if (!_.isEqual(error, reserror)) setError(reserror);
        }
      );

    /*
            fetch("https://fsrlive.airtime.pro/api/live-info")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log('result');
                    console.log(result);
                    if(!isLoaded) setIsLoaded(true);
                    if(! _.isEqual(data, result))setData(result);
                    getShowEnds(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (reserror) => {
                    console.log('error');
                    console.log(reserror);
                    if(!isLoaded)setIsLoaded(true);
                    if(!_.isEqual(error, reserror)) setError(reserror);
                }
            )
            */
  };

  const setAltPage = (pg) => {
    if (altPage == pg) {
      setAltPageState(null);
    } else {
      setAltPageState(pg);
    }
  };

  useEffect(() => {
    var TWO_MINUTE_MS = 60000;
    updateBannerData();
    const interval = setInterval(() => {
      //console.log('its been two minuntes');
      updateBannerData();
    }, TWO_MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  });

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  const clickedPlay = (e) => {
    e.preventDefault();
    //inverted cuz not yet set
    if (!iOS()) {
      if (!play) {
        bgStarRef.current.play();
        solarSysRef.current.play();
        audioRef.current.src = audioSrc;
        audioRef.current.play();
        setPlay(true);
      } else {
        if (!muted) {
          solarSysRef.current.pause();
          audioRef.current.muted = true;
          setMuted(true);
        } else {
          solarSysRef.current.play();
          //trying to find a way to catch up to live

          audioRef.current.pause();
          audioRef.current.src = audioSrc;
          audioRef.current.play();

          audioRef.current.muted = false;

          setMuted(false);
        }
      }
    } else {
      if (!play) {
        audioRef.current.src = audioSrc;
        audioRef.current.play();
        setPlay(true);
      } else {
        if (!muted) {
          //solarSysRef.current.pause();
          audioRef.current.muted = true;
          setMuted(true);
        } else {
          //solarSysRef.current.play();
          //trying to find a way to catch up to live

          audioRef.current.pause();
          audioRef.current.src = audioSrc;
          audioRef.current.play();

          audioRef.current.muted = false;

          setMuted(false);
        }
      }
    }
  };

  /*
      if(bgStarRef.current && bgStarRef.current){
          enableInlineVideo(bgStarRef.current);
          enableInlineVideo(solarSysRef.current);
      }
      */

  return (
    <div className={classes.fsrBG}>
      <ThemeProvider theme={FsrTheme}>
        <div className={classes.nav}>
          <img
            onClick={() => (window.location = "/")}
            className={classes.fsrLogo}
            src={fsrLogo}
            alt="FSR Live"
          />
        </div>
        <img
          ref={bgStarRef}
          className={classes.bgVideo}
          id="bgVid"
          src={StarBGgif}
          alt="stars"
        />
        <div className={classes.about}>
          <img
            onClick={() => (window.location = "/")}
            className={classes.fsrLogoMain}
            src={fsrLogo}
            alt="FSR Live"
          />
          {about.length > 0 &&
            about[0].fields.description.content.map((item) => {
              return <p>{item.content[0].value}</p>;
            })}
        </div>
      </ThemeProvider>
    </div>
  );
}
