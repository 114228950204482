import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SendIcon from '@material-ui/icons/Send';
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    textField:{
        height:'100%',
        width: '100%',
        border: '0px !important',
        fontFamily: 'Times New Roman',
        padding: '0px',
        fontSize: '2vh',
        backgroundColor: 'inherit',
        color: '#fff'
    },
    textFieldColor:{
        color: '#981266',
        "& .MuiFormLabel-root": {
            color: '#981266'
          },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before":{
            borderBottom: "2px solid #981266"
        },
        "& .MuiInput-underline:after":{
            borderBottom: "2px solid #981266"
        }
    },
    textFieldInputColor:{
        color: '#981266'
    },
    buttonColor:{
        backgroundColor: '#981266',
        width: '100%',
        height: '100%',

    },
    chatInputContainer:{
        width: '100%',
        fontFamily: 'Times New Roman',
        border: '2px solid #676767',
        flex: '0 1 40px'
    },
    clickable:{

     },
  }));
const ChatInput = (props) => {
    const [tempUser, setTempUser] = useState(""); 
    const [chatMessage, setchatMessage] = useState('');
    const classes = useStyles();
    const colorOptions = ['#E419A1', '#f74e48', '#f31391', '#faac86', '#e7d46b', '#ed5a86', '#e78333', '#e6e71e', '#ec7d69', '#d9d647']
    var needsUname = true;
    if(props.uname !== null){
        needsUname = false;
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const isUserProvided = props.uname && props.uname !== '';
        const ischatMessageProvided = chatMessage && chatMessage !== '';

        if (isUserProvided && ischatMessageProvided) {
            props.sendchatMessage(props.uname, chatMessage, props.color);
        } 
        else {
            //alert('Please insert an user and a chatMessage.');
        }
        setchatMessage('');
    }

    const onUserUpdate = (e) => {
        if(e.target.value.length < 24){
            setTempUser(e.target.value);
        }
    }

    const onchatMessageUpdate = (e) => {
        
        if(e.target.value.length < 256){
            setchatMessage(e.target.value);
        }
    }

    const setUname = (tempUser) => {
        //set username in cookie
        const cookies = new Cookies();

        cookies.set('fsr-chat-token', tempUser, {path: '/'});
        var colorIdx =  Math.floor(Math.random() * colorOptions.length);

        props.setUname(tempUser);
        props.setColor(colorOptions[colorIdx]);
        cookies.set('fsr-chat-color', colorOptions[colorIdx], {path: '/'});
    }
    const handleKeyDown = (e) =>{
        if (e.key === 'Enter') {
            onSubmit(e);
          }
    }

    const handleKeyDownUser = (e) => {

        if (e.key === 'Enter') {
            setUname(tempUser);
        }
    }

    if(needsUname){
        return(
            <Grid container className={classes.chatInputContainer}>
                <Grid xs={10}>
                    {/*<TextField className={classes.textFieldColor}  InputProps={{className: classes.textFieldInputColor}} value={tempUser} onChange={onUserUpdate} id="standard-basic" label="create a username" />*/}
                    <input className={classes.textField}  value={tempUser} onChange={onUserUpdate}  id="standard-basic" placeholder="create a username" onKeyDown={handleKeyDownUser}/>
                </Grid>
                <Grid xs={2}>
                    <button className={classes.buttonColor} variant="contained" color="primary" onClick={() => {setUname(tempUser)}}>
                        <AccountCircleIcon/>
                    </button>
                </Grid>
            </Grid>
        )
    }
    return (
        <Grid container className={classes.chatInputContainer}>
            <Grid xs={10}>
                {/*<TextField className={classes.textFieldColor} InputProps={{className: classes.textFieldInputColor}} value={chatMessage} onChange={onchatMessageUpdate} id="standard-basic" label="Enter Message" />*/}
                <input className={classes.textField} value={chatMessage} onChange={onchatMessageUpdate} id="standard-basic" placeholder="Enter Message" onKeyDown={handleKeyDown}/>
            </Grid>
            <Grid xs={2}>
                <button className={classes.buttonColor} variant="contained" color="primary" onClick={onSubmit}>
                    <SendIcon/>
                </button>
            </Grid>
        </Grid>
    )
};

export default ChatInput;