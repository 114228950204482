import React, { useState } from 'react';
import Haus from '../Assets/FSR_Icon1.png';
import HausGif from '../Assets/FSR_Icon1.gif';
import Calendar from '../Assets/FSR_Icon2.png';
import CalendarGif from '../Assets/FSR_Icon2.gif';
import Phone from '../Assets/FSR_Icon3.png';
import PhoneGif from '../Assets/FSR_Icon3.gif';
import MangFace from '../Assets/FSR_Icon4_Mask.png';
import MangFaceGif from '../Assets/FSR_Icon4_Mask.gif';
import {makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    iconBar:{
        position: "relative"
    },
    paddingFull:{        
        padding: "5% 20%"
    },
    paddingTablet:{        
        padding: "15% 5%"
    },
    paddingPhone:{        
        padding: " 20% 0%"
    },
    dispIconFull:{
        maxHeight: "30vh"
    },
    dispIconTablet:{
        maxHeight: "15vh"
    },
    dispIconPhone:{
        maxHeight: "15vh"
    },
    iconWrapper:{
        textAlign:"center",
    },
    gridItemFix:{
        textAlign: "center"
    }
}));


export default function IconBar(props){
    const classes = useStyles();
    const [hausSrc, setHausSrc] = useState(Haus);
    const [calSrc, setCalSrc] = useState(Calendar);
    const [phoneSrc, setPhoneSrc] = useState(Phone)
    const [mangSrc, setMangSrc] = useState(MangFace);

    var HausSrc = Haus;
    var CalendarSrc = Calendar;
    var PhoneSrc = Phone;
    var MangFaceSrc = MangFace;

    const openLinkInNewTab = (link) =>{
        window.open(link);
    }

    const MediaSize = props.mediaSize;
    var iconBarPadding;
    var iconSizeClass;
    if(MediaSize == 'monitor'){
        iconBarPadding = classes.paddingFull;
        iconSizeClass = classes.dispIconFull;
      }
      if(MediaSize == 'tablet'){
        iconBarPadding = classes.paddingTablet;
        iconSizeClass = classes.dispIconTablet;
      }
      if(MediaSize == 'phone'){
        iconBarPadding = classes.paddingPhone;
        iconSizeClass = classes.dispIconPhone;
      }
      return( 
        <div className={classes.iconBar + ' ' + iconBarPadding}>
            <Grid container className={classes.gridFix}>
                <Grid item xs={2} md={3} className={classes.gridItemFix}></Grid>
                <Grid item xs={4} md={3} className={classes.gridItemFix}>
                    <div className={classes.iconWrapper}
                        onMouseEnter={() => {setHausSrc(HausGif)}}
                        onMouseLeave={() => setHausSrc(Haus)}
                        onClick={(e) => {e.preventDefault(); openLinkInNewTab("https://www.mixcloud.com/fsrlive/")}}>
                        <img src={hausSrc} className={iconSizeClass}></img>
                    </div>
                </Grid>
                <Grid item xs={4} md={3} className={classes.gridItemFix}>
                    <div onClick={() => props.setAltPage("sched")} className={classes.iconWrapper}
                        onMouseEnter={() => {setCalSrc(CalendarGif)}}
                        onMouseLeave={() => setCalSrc(Calendar)}>
                        <img src={calSrc} className={iconSizeClass}></img>
                    </div>
                </Grid>
                <Grid item xs={2} md={3} className={classes.gridItemFix}></Grid>
            </Grid>
        </div>);

        /*
        return( 
        <div className={classes.iconBar + ' ' + iconBarPadding}>
            <Grid container className={classes.gridFix}>
                <Grid item xs={2} md={3} className={classes.gridItemFix}></Grid>
                <Grid item xs={4} md={3} className={classes.gridItemFix}>
                    <a target="_blank" href="https://www.mixcloud.com/fsrlive/" className={classes.iconWrapper}
                        onMouseEnter={() => {setHausSrc(HausGif)}}
                        onMouseLeave={() => setHausSrc(Haus)}>
                        <img src={hausSrc} className={iconSizeClass}></img>
                    </a>
                </Grid>
                <Grid item xs={4} md={3} className={classes.gridItemFix}>
                    <a target="_blank" href="http://fsrlive.airtime.pro/" className={classes.iconWrapper}
                        onMouseEnter={() => {setCalSrc(CalendarGif)}}
                        onMouseLeave={() => setCalSrc(Calendar)}>
                        <img src={calSrc} className={iconSizeClass}></img>
                    </a>
                </Grid>
                <Grid item xs={2} md={3} className={classes.gridItemFix}></Grid>
            </Grid>
        </div>);*/

      //implement when functionality exitss
      /*
    return(
        <div className={classes.iconBar + ' ' + iconBarPadding}>
            <Grid container className={classes.gridFix}>
                <Grid item xs={3} className={classes.gridItemFix}>
                    <div href="#" className={classes.iconWrapper}
                        onMouseEnter={() => {setHausSrc(HausGif)}}
                        onMouseLeave={() => setHausSrc(Haus)}>
                        <img src={hausSrc} className={iconSizeClass}></img>
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.gridItemFix}>
                    <div href="#" className={classes.iconWrapper}
                        onMouseEnter={() => {setCalSrc(CalendarGif)}}
                        onMouseLeave={() => setCalSrc(Calendar)}>
                        <img src={calSrc} className={iconSizeClass}></img>
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.gridItemFix}>
                    <div href="#" className={classes.iconWrapper} 
                        onMouseEnter={() => {setPhoneSrc(PhoneGif)}}
                        onMouseLeave={() => setPhoneSrc(Phone)}>
                        <img src={phoneSrc} className={iconSizeClass}></img>
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.gridItemFix}>
                    <div href="#" className={classes.iconWrapper}
                        onMouseEnter={() => {setMangSrc(MangFaceGif)}}
                        onMouseLeave={() => setMangSrc(MangFace)}>
                        <img src={mangSrc} className={iconSizeClass}></img>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
    */
}