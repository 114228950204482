/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import GenreLink from "./GenreLink/index.js";
import FsrTheme from "../styles/FsrTheme.js";
import gql from "graphql-tag";
import fsrLogo from "../Assets/FSRLogo.png";
import { client } from "../client";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import styles from "../styles/styles.module.css";

const useStyles = makeStyles((theme) => ({
  bgVideo: {
    objectFit: "cover",
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
  },
  viewportHeader: {
    position: "relative",
    height: "100vh",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  solarSys: {
    height: "50vh",
    top: "15vh",
    objectFit: "cover",
    position: "fixed",
  },
  solarSysTablet: {
    height: "35vh",
    top: "26vh",
    objectFit: "cover",
    position: "fixed",
  },
  /*
      sys2560: {
          height: "51%",
          width: "45%",
      },
      sys1920: {
          height: "51%",
          width: "50%",
      },
      sys1024:{
          height: "51%",
          width: "50%"
      },
      */
  playPause: {
    //zIndex: 1000,
    color: "#fff",
    height: "10vh",
    width: "10vw",
    position: "fixed",
    top: "35.5vh",
    left: "44.9vw",
  },
  playPauseTablet: {
    //zIndex: 1000,
    color: "#fff",
    height: "7vh",
    width: "15vw",
    position: "fixed",
    top: "40.5vh",
    left: "42.1vw",
  },
  iconArea: {
    width: "100%",
    position: "fixed",
    bottom: "0vh",
  },
  fsrBG: {
    backgroundColor: "#010001",
    color: "white",
    maxWidth: "1600px",
    margin: "0 auto",
  },
  shows: {
    display: "flex",
    padding: "48px 24px",
    flexWrap: "wrap",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  show: {
    width: "31%",
    border: "2px solid white",
    marginBottom: "40px",
  },
  showImg: {
    maxWidth: "100%",
  },
  showInfo: {
    padding: "8px 16px",
    width: "66%",
  },
  showText: {
    padding: "0",
    margin: "0",
    marginBottom: "10px",
  },
  fsrLogo: {
    maxWidth: "112px",
  },
  nav: {
    padding: "0 24px",
    display: "flex",
    alignItems: "center",
  },
  btn: {
    position: "relative",
    backgroundColor: "#fff",
    color: "#000",
    border: "2rem",
    borderRadius: "2rem",
    textAlign: "center",
    width: "50%",
    maxWidth: "500px",
    fontSize: "30px",
    maxHeight: "60px",
    height: "60px",
    cursor: "pointer",
    textDecoration: "none",
    paddingTop: "10px",
  },
  navBtns: {
    display: "flex",
    marginTop: "16px",
    justifyContent: "space-around",
  },
  btnNot: {
    background: "black",
    color: "white",
    border: "2px solid white",
  },
  showLinks: {
    display: "flex",
    fontSize: "14px !important",
    flexDirection: "column",
    width: "33%",
    paddingTop: "8px",
    paddingRight: "16px",
  },
  flexIt: {
    display: "flex",
    width: "100%",
  },
  navNew: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    ["@media (max-width:940px)"]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  navBtns: {
    display: "flex",
    marginTop: "16px",
    justifyContent: "space-around",
  },
  btnNot: {
    background: "black",
    color: "white",
    border: "2px solid white",
  },
  fsrLogo: {
    maxWidth: "112px",
    ["@media (max-width:940px)"]: {
      maxWidth: "182px",
      marginBottom: "20px",
    },
  },
  nav: {
    padding: "0 24px",
    display: "flex",
    alignItems: "center",
    ["@media (max-width:940px)"]: {
      flexDirection: "column",
    },
  },
  btn: {
    position: "relative",
    backgroundColor: "#fff",
    color: "#000",
    border: "2rem",
    borderRadius: "2rem",
    textAlign: "center",
    width: "50%",
    maxWidth: "350px",
    fontSize: "20px",
    maxHeight: "30px",
    height: "30px",
    cursor: "pointer",
    textDecoration: "none",
    ["@media (max-width:940px)"]: {
      marginBottom: "20px",
    },
  },
  navNew: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    ["@media (max-width:940px)"]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  navBtns: {
    display: "flex",
    marginTop: "16px",
    justifyContent: "space-around",
  },
  btnNot: {
    background: "black",
    color: "white",
    border: "2px solid white",
  },
  mainInfo: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "@media (max-width:1200px)": {
      flexDirection: "column",
    },
  },
  mainImg: {
    width: "40%",
    marginRight: "50px",
    border: "2px solid white",
    objectFit: "cover",
    "@media (max-width:1200px)": {
      width: "60%",
      marginBottom: "20px",
    },
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  mainTxt: {
    width: "60%",
    "@media (max-width:1200px)": {
      width: "100%",
    },
  },
  title: {
    fontSize: "60px",
    margin: 0,
    "@media (max-width:768px)": {
      fontSize: "30px",
    },
  },
  desc: {
    fontSize: "40px",
    margin: "10px 0px",
    "@media (max-width:768px)": {
      fontSize: "22px",
    },
  },
  bio: {
    // width: "80%",
  },
  audio: {
    marginTop: "20px",
    display: "flex",
    fontSize: "26px",
    alignItems: "center",
    "@media (max-width:768px)": {
      fontSize: "18px",
      marginBottom: "20px",
    },
    "& .MuiSvgIcon-root": {
      height: "2em",
      width: "2em",
      border: "2px solid white",
      borderRadius: "50px",
      marginRight: "20px",
    },
    // "& div": {
    //   height: "180px !imporant",
    // },
  },
  archiveLink: {
    textDecoration: "none",
    color: "white",
    transition: "all 0.3s ease-in-out",
    fontSize: "20px",
    marginTop: "20px",
  },
  bottomInfo: {
    display: "flex",
    fontSize: "20px",
    justifyContent: "space-between",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
    // width: "80%",
  },
  showExt: {
    fontSize: "16px",
    marginBottom: "10px",
  },
  showDisplay: {
    display: "flex",
    flexDirection: "column",
  },
}));

const TEST_QUERY = gql`
  {
    books {
      title
      author
    }
  }
`;

export default function ShowPage() {
  const classes = useStyles();
  const params = useParams();
  const [showData, setShow] = useState({});
  const audioRef = useRef(null);
  const [play, setPlay] = useState(false);
  const [muted, setMuted] = useState(false);
  const [linkData, setLinkData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await client.getEntries();
      setLinkData(data);
      const shows = data.items.filter((item) => {
        return item.sys.contentType.sys.id === "show";
      });

      // const data = await client.getEntries({ content_type: "shows" });
      shows.map((show) => {
        if (show.fields.slug.includes(params.id)) {
          setShow(show.fields);
        }
      });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickedPlay = (e) => {
    e.preventDefault();

    if (!play) {
      audioRef.current.play();
      setPlay(true);
    } else {
      if (!muted) {
        audioRef.current.muted = true;
        setMuted(true);
      } else {
        audioRef.current.pause();
        audioRef.current.play();

        audioRef.current.muted = false;

        setMuted(false);
      }
    }
  };

  console.log(showData.audioLink);

  return (
    <div className={`${classes.fsrBG} fade-in`}>
      <ThemeProvider theme={FsrTheme}>
        {/* <div className={classes.nav}>
          <img
            onClick={() => (window.location = "/")}
            className={classes.fsrLogo}
            src={fsrLogo}
            alt="FSR Live"
          />
        </div> */}
        <div className={classes.nav}>
          <img
            onClick={() => (window.location = "/")}
            className={classes.fsrLogo}
            src={fsrLogo}
            alt="FSR Live"
          />
          <div className={classes.navNew}>
            <a href="/shows" className={classes.btn}>
              Shows
            </a>
            <a href="/genres" className={`${classes.btn} ${classes.btnNot}`}>
              Genres
            </a>
          </div>
        </div>
        {showData && Object.keys(showData).length > 0 && (
          <div className={classes.shows}>
            <div className={classes.mainInfo}>
              <img
                className={classes.mainImg}
                src={`https://${showData?.mainImage?.fields.file.url}`}
                alt={showData.title}
              />
              <div className={classes.mainTxt}>
                <p className={classes.title}>{showData.title}</p>
                <p className={classes.desc}>{showData.time}</p>
                <div className={classes.audio}>
                  <ReactPlayer url={showData.audioLink} controls />
                  {/* <audio ref={audioRef} src={showData.audioLink}></audio>
                  {!play ? (
                    <PlayArrowIcon onClick={clickedPlay} />
                  ) : muted ? (
                    <PlayArrowIcon onClick={clickedPlay} />
                  ) : (
                    <PauseIcon onClick={clickedPlay} />
                  )}
                  <p>Play {showData.title}</p> */}
                </div>
                {showData.genres.map((genre, i) => {
                  return (
                    <GenreLink linkData={linkData} noList id={genre.sys.id} />
                  );
                })}
                <p className={classes.bio}>
                  {showData?.description?.content[0]?.content[0].value}
                </p>
                <div className={classes.bottomInfo}>
                  <a
                    className={`${classes.archiveLink} ${styles.archiveLink}`}
                    href={showData.archiveLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ARCHIVE
                  </a>
                  <div className={classes.showDisplay}>
                    <p>{showData.recommended && "RECOMMENDED SHOWS"}</p>
                    {showData.recommended &&
                      showData.recommended.map((show, i) => {
                        if (i <= 2) {
                          return (
                            <a
                              className={`${styles.testGenre} ${classes.showExt}`}
                              href={`/shows/${show.fields.slug}`}
                            >
                              {show.fields.title}
                            </a>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ThemeProvider>
    </div>
  );
}
