
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
      minHeight: '15vh',
      textAlign: 'center',
      padding: '20px',
      border: '2px solid black',
      //backgroundColor: '#BBDEF0',
      //boxShadow: '1px 1px 1px 1px #E419A1',
      boxShadow: '4px 4px 6px -1px',
      fontWeight: 'bold',

  },
  control: {
    padding: theme.spacing(2),
  },
  header:{
      fontSize: '3rem',
      textAlign: 'center',
      //color: '#BBDEF0',
      paddingBottom: '60px'
  },
  selectorContainer:{
      padding: '5%'
  }
}));

export default function Selector(props){

    const classes = useStyles();
    return(
        <div className={classes.selectorContainer}>
            <div className={classes.header}>Admin Section Radio</div>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}/>
                <Grid item xs={12}>
                    <Paper className={classes.paper} onClick={() => {props.setCurrView("show")}}>
                        Modify Show details
                    </Paper>
                </Grid>
                {/*}
                <Grid item xs={3}>
                    <Paper className={classes.paper} onClick={() => {props.setCurrView("chat")}}>
                        Manage Chat features
                    </Paper>
                </Grid>
                */}
                <Grid item xs={4}/>
            </Grid>
        </div>
    )
}