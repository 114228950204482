import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    showCard:{
        backgroundColor: '#BBDEF0',
        borderRadius: '5px',
        padding: '0.5rem',
        color: '#010001',
        textAlign: 'left',
        boxShadow: '1px 1px 1px 1px #e419a1'
    },
    name:{
        fontWeight: 'bold',
    },
    description:{
        fontSize: '1.5vh',
        width:'100%'
    },
    showImg:{
        maxWidth: '100%'
    },
    topRight:{
        float: 'right'
    }
}));


export default function ResponsiveDialog(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const [show, setShow] = React.useState({});
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const alterShow = (val, field) => {
        var showCopy = _.cloneDeep(show);
        showCopy[field] = val;
        if(!_.isEqual(show, showCopy)) setShow(showCopy);
    }   
    const imageUpload = (e) => {
        if(e.target.files && e.target.files[0]){
            var file = e.target.files[0];
            
            var reader = new FileReader();
            reader.readAsBinaryString(file);

            reader.onload = function() {
                var tempShow = _.cloneDeep(show);
                tempShow.image = btoa(reader.result);
                if(!_.isEqual(tempShow, show)) setShow(tempShow)
            };
            reader.onerror = function() {
                console.log('there are some problems');
            };
        }
    }   
    const createShow = () =>{
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + '/api/shows/create?token=' + props.authToken,
        {
            Name:show.name,
            Description:show.description,
            Image:show.image,
            Host:null,
            Genre:null,
            ShowTime:null
        })
            .then(response =>{ props.updateShows(response.data); handleClose();});
    }

    return (
        <div>
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{"Create New Show"}</DialogTitle>
            <DialogContent>
                <div className={classes.imgBox}>
                    <img className={classes.showImg} src={'data:image/jpeg;base64, ' + show.image}/>
                </div>
                {loading ? <CircularProgress/> :
                <>
                    <Input type="file" onChange={imageUpload}></Input>
                    <div>
                        Name: <TextField className={classes.name}
                            value={show.name}
                            onChange={(e) => alterShow(e.target.value, 'name')}
                        />
                    </div>
                    <div>
                        Description: 
                        <div>
                            <TextField className={classes.description}
                                value={show.description}
                                
                                multiline
                                onChange={(e) => alterShow(e.target.value, 'description')}
                            />
                        </div>
                    </div>
                    <DialogActions>
                    <Button autoFocus onClick={createShow} color="primary">
                        Submit
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Cancel
                    </Button>
                    </DialogActions>
                </>
                }   
            </DialogContent>
        </Dialog>
        </div>
    );
}
