import React, { useEffect, useState } from 'react';
//import logo from './logo.svg';
//import '../App.css';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Selector from './Selector';
import ShowDetails from './Shows/ShowDetails';
import Chat from './Chat/Chat';
import Cookies from 'universal-cookie';
import Login from './Login';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  asr:{
    width: '100%',
    height:'100%',
    //backgroundColor: '#010001',
    //color: '#BBDEF0'
  }
}));

export default function AppWrapper(){
  const [authToken, setAuthToken] = useState(null);
  const classes = useStyles();
  console.log('url');
  console.log(process.env.REACT_APP_API_URL);

  useEffect(() => {
    var authCheckUrl = process.env.REACT_APP_API_URL + "/api/authorization/check?token=";
    
    const cookies = new Cookies()
    var authToken = cookies.get('fsr-auth-token');
    console.log({authToken: authToken});

    if(typeof authToken == undefined){
      setAuthToken("empty");
    }else{

      axios.post(authCheckUrl + authToken).then(response => {
        if(response.data){
          setAuthToken(authToken);
        }else{
          setAuthToken("empty")
        }
      })

    }
  });


  if(authToken){
    return <App authTokenCookie={authToken}/>
  }else{
    return (
      <div className={classes.asr}>
      </div>
    )
  }

}


function App(props) {
  const [currView, setCurrView] = useState('root');
  const [authToken, setAuthToken] = useState(() => {
    if(props.authTokenCookie && props.authTokenCookie !== "empty"){
      return props.authTokenCookie;
    }else{
      return null;
    }
  });
  const classes = useStyles();

  //on auth token update
  useEffect(() => {
    if(authToken){
      const cookies = new Cookies();
      var authTokenFromCookie = cookies.get('fsr-auth-token');
      if(authTokenFromCookie !== authToken){
        cookies.set('fsr-auth-token', authToken, {path: '/'});
      }
    }
  },[authToken])
  
  


  var view = (<></>)
  if(authToken == null){
    view = <Login setAuthToken={setAuthToken}/>
  }else
  {
    if(currView == "root"){
      view = <Selector setCurrView={setCurrView} authToken={authToken}/>
    }if(currView == "show"){
      view = <ShowDetails setCurrView={setCurrView} authToken={authToken}/>
    }
    if(currView == "chat"){
      view = <Chat setCurrView={setCurrView} authToken={authToken}/>
    }
  }
  
  return (
    <div className={classes.asr}>
      {view}
    </div>
  );
}

