import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Grid from "@material-ui/core/Grid";
import fsrLogo from '../Assets/FSRLogo.png'
import Banner from './Banner';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import classesFromStyleSheet from '../styles/styles.module.css'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    background:'transparent',
    boxShadow: 'none'
  },
  menuButton: {
    float: 'right',
    position: 'absolute'
  },
  title: {
    flexGrow: 1,
  },
  icon:{
    maxHeight: '8vh',
    paddingTop:'1vh'
  },
  iconTablet:{
    maxHeight: '8vh',
    paddingTop:'1vh'
  },
  iconPhone: {
    maxHeight: '6vh',
    paddingTop:'0.5vh'
  },
  wider:{
    minWidth: '50%'
  }
}));

export default function FsrAppBar(props) {
  const classes = useStyles();


  const MediaSize = props.mediaSize;

  var IconClassName;
  if(MediaSize == 'monitor'){
    IconClassName = classes.icon;
  }
  if(MediaSize == 'tablet'){
    IconClassName = classes.iconTablet;
  }
  if(MediaSize == 'phone'){
    IconClassName = classes.iconPhone;
  }

  var playPauseJsx;
  if(props.altPage !== null ){
    playPauseJsx = (<div className={classesFromStyleSheet.playPauseAppbar}>
      {!props.playing ? <PlayArrowIcon className={classesFromStyleSheet.playpauseAppbar} onClick={props.clickedPlay}/>  : 
          props.muted ? <PlayArrowIcon className={classesFromStyleSheet.playpauseAppbar} onClick={props.clickedPlay}/>  : 
          <PauseIcon className={classesFromStyleSheet.playpauseAppbar} onClick={props.clickedPlay}/> }
    </div>)
  }
  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar}>
        <Toolbar>
          {/*
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <img src={fsrLogo} className={classes.icon}/>
          </IconButton>
          */}
          <div className={classes.menuButton}>
            <img src={fsrLogo} className={IconClassName}/>
          </div>
          <Grid justify={"center"} alignItems={"center"} container>
            <Grid className={classes.wider} style={{ justifySelf: "flex-start" }} item>
                <Banner showInfo={props.showInfo} showLoaded={props.showLoaded} mediaSize={MediaSize}/>
            </Grid>
          </Grid>
          {playPauseJsx}
          {/*<Button color="inherit">Login</Button>*/}
        </Toolbar>
      </AppBar>
    </div>
  );
}