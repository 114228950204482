import React, { useState, useEffect, useRef } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

import ChatWindow from './ChatWindow';
import ChatInput from './ChatInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backArrow:{

    },
    showDetailsContainer:{
        textAlign: 'center'
    },
    showDeetHeader: {
        fontSize: '3rem'
    }
    
}));

export default function Chat(props){
    const [ connection, setConnection ] = useState(null);
    const [ chat, setChat ] = useState([]);
    const latestChat = useRef(null);
    const classes = useStyles();

    latestChat.current = chat;

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(process.env.API_URL + '/hubs/chat')
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    console.log('Connected!');
    
                    connection.on('ReceiveMessage', message => {
                        const updatedChat = [...latestChat.current];
                        updatedChat.push(message);
                    
                        setChat(updatedChat);
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    const sendMessage = async (user, message) => {
        const chatMessage = {
            user: user,
            message: message
        };

        if (connection.connectionStarted) {
            try {
                await connection.send('SendMessage', chatMessage);
            }
            catch(e) {
                console.log(e);
            }
        }
        else {
            alert('No connection to server yet.');
        }
    }


    return(
        <div>
            <div className={classes.backArrow} onClick={() => {props.setCurrView("root")}}>back</div>
            <div className={classes.showDetailsContainer}>
                <div className={classes.showDeetHeader}>
                    Chat Manipulation
                </div>
                <div className={classes.warning}>
                    Lets see Whatsup
                </div>
                <div>
                    <ChatInput sendMessage={sendMessage} />
                    <hr />
                    <ChatWindow chat={chat}/>
                </div>
            </div>
        </div>
    )
}