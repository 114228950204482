import React, { useEffect, useState } from "react";
// import { client } from "../../client";
import styles from "../../styles/styles.module.css";

export default function GenreLink({ linkData, id, noList }) {
  const [genre, setGenres] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      linkData.items[0].fields.genres.map((item) => {
        if (item.sys.id === id) {
          setGenres(item.fields);
        }
      });
    };
    fetchData();
  }, []);

  if (noList) {
    return (
      <a
        className={`${styles.genreLink} ${styles.pad}`}
        href={`/genres/${genre.slug}`}
      >
        {genre.title}
      </a>
    );
  }

  return (
    <li>
      <a className={styles.genreLink} href={`/genres/${genre.slug}`}>
        {genre.title}
      </a>
    </li>
  );
}
