import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import MichromaTTF from './fonts/Michroma-Regular.ttf';

const michroma = {
  fontFamily: 'Michroma',
  fontStyle: 'normal',
  fontDispaly: 'swap',
  fontWeight: 400,
  src: `
    local('Michroma'),
    local('Michroma-Regular'),
    url(${MichromaTTF}) format ('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Michroma, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [michroma],
      },
    },
  },
});

export default theme;