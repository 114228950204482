/* eslint-disable no-useless-computed-key */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import GenreLink from "./GenreLink/index.js";
import FsrTheme from "../styles/FsrTheme.js";
import gql from "graphql-tag";
import fsrLogo from "../Assets/FSRLogo.png";
import { client } from "../client";
import { slide as Menu } from "react-burger-menu";

const useStyles = makeStyles((theme) => ({
  bgVideo: {
    objectFit: "cover",
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
  },
  viewportHeader: {
    position: "relative",
    height: "100vh",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  solarSys: {
    height: "50vh",
    top: "15vh",
    objectFit: "cover",
    position: "fixed",
  },
  solarSysTablet: {
    height: "35vh",
    top: "26vh",
    objectFit: "cover",
    position: "fixed",
  },
  /*
    sys2560: {
        height: "51%",
        width: "45%",    
    },
    sys1920: {
        height: "51%",
        width: "50%",    
    },
    sys1024:{
        height: "51%",
        width: "50%"
    },
    */
  playPause: {
    //zIndex: 1000,
    color: "#fff",
    height: "10vh",
    width: "10vw",
    position: "fixed",
    top: "35.5vh",
    left: "44.9vw",
  },
  playPauseTablet: {
    //zIndex: 1000,
    color: "#fff",
    height: "7vh",
    width: "15vw",
    position: "fixed",
    top: "40.5vh",
    left: "42.1vw",
  },
  iconArea: {
    width: "100%",
    position: "fixed",
    bottom: "0vh",
  },
  fsrBG: {
    backgroundColor: "#010001",
    color: "white",
    maxWidth: "1600px",
    margin: "0 auto",
    overflowX: "hidden",
  },
  shows: {
    display: "flex",
    padding: "48px 24px",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  show: {
    width: "31%",
    ["@media (max-width:1400px)"]: {
      width: "49%",
    },
    ["@media (max-width:940px)"]: {
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    "@media (max-width:768px)": {
      width: "100%",
    },
    border: "2px solid white",
    marginBottom: "40px",
  },
  showImg: {
    maxWidth: "100%",
    width: "100%",
  },
  showInfo: {
    padding: "8px 16px",
    width: "66%",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  showText: {
    padding: "0",
    margin: "0",
    marginBottom: "10px",
  },
  fsrLogo: {
    maxWidth: "112px",
    ["@media (max-width:940px)"]: {
      maxWidth: "182px",
      marginBottom: "20px",
    },
  },
  nav: {
    padding: "0 24px",
    display: "flex",
    alignItems: "center",
    ["@media (max-width:940px)"]: {
      flexDirection: "column",
    },
  },
  btn: {
    position: "relative",
    backgroundColor: "#fff",
    color: "#000",
    border: "2rem",
    borderRadius: "2rem",
    textAlign: "center",
    width: "50%",
    maxWidth: "350px",
    fontSize: "20px",
    maxHeight: "30px",
    height: "30px",
    cursor: "pointer",
    textDecoration: "none",
    ["@media (max-width:940px)"]: {
      marginBottom: "20px",
    },
  },
  navNew: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    ["@media (max-width:940px)"]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  navBtns: {
    display: "flex",
    marginTop: "16px",
    justifyContent: "space-around",
  },
  btnNot: {
    background: "black",
    color: "white",
    border: "2px solid white",
  },
  showLinks: {
    display: "flex",
    fontSize: "14px !important",
    flexDirection: "column",
    width: "33%",
    paddingTop: "8px",
    paddingRight: "16px",
    "@media (max-width:768px)": {
      width: "100%",
      padding: "8px 16px",
    },
  },
  flexIt: {
    display: "flex",
    width: "100%",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
}));

const TEST_QUERY = gql`
  {
    books {
      title
      author
    }
  }
`;

export default function Shows() {
  const classes = useStyles();
  const [shows, setShows] = useState([]);
  const [linkData, setLinkData] = useState([]);

  useEffect(() => {
    // const fetchData = async () => {
    // const data = await client.getEntries({ content_type: "shows" });
    //   setShows(data.items[0].fields.shows);
    // };
    // fetchData();
    const fetchData = async () => {
      const data = await client.getEntries({ content_type: "genres" });
      setLinkData(data);
      const data2 = await client.getEntries({ content_type: "shows" });
      // console.log(data2.items[0].fields.activeShows);

      // const shows = data.items.filter((item) => {
      //   return item.sys.contentType.sys.id === "show";
      // });
      console.log(data2.items[0].fields.activeShows);
      setShows(data2.items[0].fields.activeShows);
    };
    fetchData();
  }, []);

  console.log(shows);

  return (
    <div className={`${classes.fsrBG} fade-in`}>
      <ThemeProvider theme={FsrTheme}>
        <div className={classes.nav}>
          <img
            onClick={() => (window.location = "/")}
            className={classes.fsrLogo}
            src={fsrLogo}
            alt="FSR Live"
          />
          <div className={classes.navNew}>
            <a href="/shows" className={classes.btn}>
              Shows
            </a>
            <a href="/genres" className={`${classes.btn} ${classes.btnNot}`}>
              Genres
            </a>
          </div>
        </div>
        <div className={classes.shows}>
          {shows.length > 0 &&
            shows.map(
              ({ fields: { mainImage, title, time, genres, slug } }) => {
                console.log(title === "Past Shows");
                if (title === "Past Shows") {
                  return <h3 style={{ width: "100%" }}>{title}</h3>;
                }
                return (
                  <div
                    key={title}
                    onClick={() => (window.location = `/shows/${slug}`)}
                    className={classes.show}
                  >
                    <img
                      className={classes.showImg}
                      src={`https://${mainImage.fields.file.url}`}
                      alt="title"
                    />
                    <div className={classes.flexIt}>
                      <div className={classes.showInfo}>
                        <p className={classes.showText}>{title}</p>
                        <p className={classes.showText}>{time}</p>
                      </div>
                      <div className={classes.showLinks}>
                        {genres.map((genre, i) => {
                          if (i <= 2) {
                            return (
                              <GenreLink
                                linkData={linkData}
                                key={genre.sys.id}
                                id={genre.sys.id}
                              />
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                );
              }
            )}
        </div>
      </ThemeProvider>
    </div>
  );
}
