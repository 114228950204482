import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classes from "../styles/styles.module.css"



export default function Banner(props){

    const MediaSize = props.mediaSize;

    const getShowData = (showInfo) => {
        //displays current show name else displays file name
        if(showInfo.currentShow && showInfo.currentShow[0] && showInfo.currentShow[0].name) return showInfo.currentShow[0].name.replace("&#039;", "'").replace("&quot;", '"')

        var data = showInfo.current;
        console.log(data)
        var bannerInfo = data.name;
        var startTime = data.starts;
        //return bannerInfo + ' ~~~ ' + startTime;
       
        bannerInfo = bannerInfo.replace("&quot;", '"');
        bannerInfo = bannerInfo.replace("&#039;", "'");
        bannerInfo = bannerInfo.replace("&#039;Z", "'");

        return bannerInfo;
    }
    var smallMonitorClass = MediaSize == 'tablet' ? classes.marqueeTablet : MediaSize == 'phone' ? classes.marqueePhone : '';

    var bannerContents = <>Loading...</>;
    if(props.showLoaded){
        // console.log(props.showInfo, 'showinfo')
        // props.showInfo = props.showInfo.replace("&#039;Z", "'")
        // console.log(props.showInfo)
         //change previous to current
        if(props.showInfo && props.showInfo.current){
            bannerContents = getShowData(props.showInfo);
        }else{
            bannerContents = <>No current show</>
        }

    }
    return (
        <div className={classes.marquee + ' ' + smallMonitorClass}>
            <span>
            {bannerContents}
            </span>
        </div>
    );
}