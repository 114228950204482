import React, { useEffect, useRef, useState } from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import FsrTheme from "../styles/FsrTheme.js";
import gql from "graphql-tag";
import fsrLogo from "../Assets/FSRLogo.png";
import { client } from "../client";
import styles from "../styles/styles.module.css";

const useStyles = makeStyles((theme) => ({
  bgVideo: {
    objectFit: "cover",
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
  },
  viewportHeader: {
    position: "relative",
    height: "100vh",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  solarSys: {
    height: "50vh",
    top: "15vh",
    objectFit: "cover",
    position: "fixed",
  },
  solarSysTablet: {
    height: "35vh",
    top: "26vh",
    objectFit: "cover",
    position: "fixed",
  },
  /*
    sys2560: {
        height: "51%",
        width: "45%",    
    },
    sys1920: {
        height: "51%",
        width: "50%",    
    },
    sys1024:{
        height: "51%",
        width: "50%"
    },
    */
  playPause: {
    //zIndex: 1000,
    color: "#fff",
    height: "10vh",
    width: "10vw",
    position: "fixed",
    top: "35.5vh",
    left: "44.9vw",
  },
  playPauseTablet: {
    //zIndex: 1000,
    color: "#fff",
    height: "7vh",
    width: "15vw",
    position: "fixed",
    top: "40.5vh",
    left: "42.1vw",
  },
  iconArea: {
    width: "100%",
    position: "fixed",
    bottom: "0vh",
  },
  fsrBG: {
    backgroundColor: "#010001",
    color: "white",
    maxWidth: "1600px",
    margin: "0 auto",
  },
  genres: {
    display: "flex",
    padding: "48px 24px",
    flexWrap: "wrap",
    flexDirection: "column",
    "@media (max-width:940px)": {
      padding: "24px",
    },
    // ["@media (max-width:940px)"]: {
    //   paddingTop
    // },
  },
  show: {
    width: "31%",
    border: "2px solid white",
    marginBottom: "40px",
    "@media (max-width:1400px)": {
      width: "49%",
    },
    "@media (max-width:940px)": {
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  showImg: {
    maxWidth: "100%",
  },
  showInfo: {
    padding: "8px 16px",
  },
  showText: {
    padding: "0",
    margin: "0",
    marginBottom: "10px",
  },
  fsrLogo: {
    maxWidth: "112px",
    "@media (max-width:940px)": {
      maxWidth: "182px",
      marginBottom: "20px",
    },
  },
  nav: {
    padding: "0 24px",
    display: "flex",
    alignItems: "center",
    "@media (max-width:940px)": {
      flexDirection: "column",
    },
  },
  btn: {
    position: "relative",
    backgroundColor: "#fff",
    color: "#000",
    border: "2rem",
    borderRadius: "2rem",
    textAlign: "center",
    width: "50%",
    maxWidth: "350px",
    fontSize: "20px",
    maxHeight: "30px",
    height: "30px",
    cursor: "pointer",
    textDecoration: "none",
    "@media (max-width:940px)": {
      marginBottom: "20px",
    },
  },
  navNew: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    "@media (max-width:940px)": {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  navBtns: {
    display: "flex",
    marginTop: "16px",
    justifyContent: "space-around",
  },
  btnNot: {
    background: "black",
    color: "white",
    border: "2px solid white",
  },
  genre: {
    fontSize: "32px",
    color: "white",
    marginBottom: "24px",
  },
}));

const TEST_QUERY = gql`
  {
    books {
      title
      author
    }
  }
`;

export default function Genres() {
  const classes = useStyles();
  const [genres, setGenres] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await client.getEntries({ content_type: "genres" });
      setGenres(data.items[0].fields.genres);
    };
    fetchData();
  }, []);

  return (
    <div className={`${classes.fsrBG} fade-in`}>
      <ThemeProvider theme={FsrTheme}>
        <div className={classes.nav}>
          <img onClick={() => (window.location = "/")} className={classes.fsrLogo} src={fsrLogo} alt="FSR Live" />
          <div className={classes.navNew}>
            <a href="/shows" className={`${classes.btn} ${classes.btnNot}`}>
              Shows
            </a>
            <a href="/genres" className={`${classes.btn}`}>
              Genres
            </a>
          </div>
        </div>
        <div className={classes.genres}>
          {genres &&
            genres.map(({ fields: { title, slug, description } }) => {
              return (
                <div className={classes.genre}>
                  <a className={styles.testGenre} href={`/genres/${slug}`}>
                    {title}
                  </a>
                </div>
              );
            })}
        </div>
      </ThemeProvider>
    </div>
  );
}
